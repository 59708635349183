@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Plus+Jakarta+Sans:wght@200;300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#x-dot-document-auto-capture pattern,
#x-dot-face-auto-capture pattern,
#x-dot-magnifeye-liveness pattern {
  display: none !important;
}

:root {
  --dashboard-color: #eff1f5;
  --primary-color: #373a8a;
  --secondary-color: #faa82c;
  --font-color-btn: #ffffff;
  --black: #212122;
  --black-secondary: #3d3d3d;
  --font-title: #26285a;
  --font-accent: #838383;
  --text-error: #e69494;
  --purple: #583eee;
  --dark-purple: #34379c;
  --info-terms: #e3e1eb;
  --info-icon-terms: #979797;
  --bg-accent: #e1e2e4;
  --bg-signature: #f5f5f5;
  --border-color: #aaa;
  --error: #dd4577;
  --bg-content: #eff1f5;
  --btn-circle-color: #d3d3d3;
  /* Status */
  --bg-tag-pending: #f0aee6a6;
  --color-tag-pending: #8d1ba3;
  --bg-tag-approval: #b2f1ce83;
  --color-tag-approval: #167166;
  --bg-tag-rejected: #f5b9b996;
  --color-tag-rejected: #d8154c;
  --bg-tag-sign-pending: #fdd38a6b;
  --color-tag-sign-pending: #794f09;
  --bg-tag-sign-success: #b5baff96;
  --color-tag-sign-success: #1f2197;
  --shadow-cards: #0000001a 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  /* Signature */
  --bg-card-sign: #b5e1eca2;
  --bg-select-card-1: #9fa5ffc9;
  --bg-select-card-2: #ffbe93cc;
  --bg-select-card-3: #bc91fcbd;
}

body {
  font-family:
    "Plus Jakarta Sans",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

video {
  border-radius: 8px;
}
strong {
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu";
}

input {
  box-shadow: none !important;
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  outline: none;
  border: none !important;
  font-size: clamp(0.85rem, 0.86rem, 0.88rem);
  padding: 0.5rem 1rem;
  pointer-events: all !important;
  text-transform: capitalize !important;
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu";
}

button:hover,
button:active,
button:focus,
button:focus-visible {
  outline: none !important;
}
button:disabled,
button:disabled:hover,
button:disabled:active,
button:disabled:focus,
button:disabled:focus-visible {
  background-color: #959595;
  cursor: not-allowed;
  outline: none !important;
}

a {
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu" !important;
  outline: none;
}

ul,
ol {
  padding-left: 40px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu";
  font-weight: 500;
}

span {
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu" !important;
  font-weight: normal;
}

/* Custom elements */
.custom_primary_btn {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 40px;
}

.custom_primary_btn:hover,
.custom_primary_btn:focus,
.custom_primary_btn:active {
  background-color: var(--primary-color);
  cursor: pointer;
}

.try_again_btn {
  border-radius: 40px;
  background-color: #2d9aa8;
  color: #fff;
}

.try_again_btn:hover,
.try_again_btn:focus,
.try_again_btn:active {
  background-color: #2d9aa8;
  cursor: pointer;
}

/* PDF */
.custom_button_actions_pdf {
  background-color: #1b1b1b17 !important;
  padding: 0 !important;
  width: 32px !important;
  height: 30px !important;
  min-width: auto !important;
  color: #fff !important;
  pointer-events: all !important;
  outline: none !important;
  border: none !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.custom_button_actions_pdf:hover,
.custom_button_actions_pdf:active,
.custom_button_actions_pdf:focus,
.custom_button_actions_pdf:focus-visible {
  background-color: #1b1b1b3d !important;
}

.custom_button_actions_pdf:disabled {
  cursor: not-allowed !important;
  user-select: all !important;
  color: #ffffff8a !important;
  background-color: #00000009 !important;
}

/* Modal */
.content_main_modal {
  display: grid;
  place-content: center;
}
.content_dektop_modal {
  /* width: 600px; */
  margin: 0 auto;
  background-color: #fff;
  outline: none;
  border-radius: 10px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
/* Style mobile */
.content_mobile_modal_main {
  margin: 0 auto;
  background-color: #fff;
  outline: none;
  border-radius: 10px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.content_mobile_modal_main .content_mobile_main {
  padding: 0.5rem 0rem;
}

.content_mobile_modal_main .content_mobile_main p {
  text-align: center;
  padding: 0 1rem;
}

.icon_modal {
  width: 145px;
  height: 145px;
  object-fit: scale-down;
}

.body_modal_custom {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.information_modal {
  margin-bottom: 1.4rem;
  color: var(--black-secondary);
  padding: 0 2.5rem;
  line-height: 22px;
  text-align: center;
}

.more_information_modal {
  text-align: center;
  line-height: 20px;
  font-size: clamp(0.79rem, 0.83rem, 0.86rem);
  padding: 1rem 2.5rem 1rem;
  color: var(--font-accent);
}

.options {
  display: flex;
  column-gap: 10px;
}

.content_fingerprint {
  display: flex;
  flex-direction: column;
  background-color: #eff1f5;
}

@media only screen and (orientation: landscape) {
  .content_mobile_modal_main {
    height: 90%;
    overflow: auto;
  }
}

/* Custom */
.MuiTooltip-tooltip {
  padding: 0.5rem 0.7rem !important;
  background-color: #141414cb !important;
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu" !important;
}

.MuiTooltip-arrow::before {
  background-color: #141414cb !important;
}

.MuiFormControlLabel-label {
  margin-left: 10px !important;
  font-size: 0.77rem !important;
  color: var(--font-accent) !important;
}

.MuiTableCell-root {
  font-family:
    "Montserrat",
    sans-serif,
    -apple-system,
    "Roboto",
    "Ubuntu" !important;
}
